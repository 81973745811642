/* eslint-disable react-hooks/exhaustive-deps */

import {
    Row,
    Col
} from 'antd'

import LoginInfo from './components/loginInfo'
import Header from '../../../common/components/header'
import Footer from '../../../common/components/footer'

export default function Login() {
    return (
        <>
            <Header />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row>
                    <Col span={24}>
                        <LoginInfo />
                    </Col>
                </Row>
            </div>

            <Footer />
        </>
    )
}