/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import "../header/css/index.css"
import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
	Drawer,
	Button,
	Menu,
	Modal,
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../mamager/ScreenManager"

export default function Header(props) {
	const navigate = useNavigate()
	const location = useLocation()

	const { width } = useDimensions()

	const [state, setState] = useState({
		current: 'mail',
		visible: false
	})

	const showDrawer = () => {
		setState({ visible: true })
	}

	const onClose = () => {
		setState({ visible: false })
	}

	const genRightMenu = () => {
		return (
			<Menu mode="inline">
				<Menu.Item
					key="1"
					onClick={() => {
						// navigate("/")
						// window.scrollTo(0, 0)
						// onClose()
						modalRepair.warning(configRepair)
					}}
				>
					เกี่ยวกับเรา
				</Menu.Item>

				<Menu.Item
					key="2"
					onClick={() => {
						// navigate("/")
						// window.scrollTo(0, 0)
						// onClose()
						modalRepair.warning(configRepair)
					}}
				>
					บริการของเรา
				</Menu.Item>

				<Menu.Item
					key="3"
					onClick={() => {
						// navigate("/")
						// window.scrollTo(0, 0)
						// onClose()
						modalRepair.warning(configRepair)
					}}
				>
					ผลงาน
				</Menu.Item>

				<Menu.Item
					key="4"
					onClick={() => {
						// navigate("/")
						// window.scrollTo(0, 0)
						// onClose()
						modalRepair.warning(configRepair)
					}}
				>
					ติดต่อเรา
				</Menu.Item>
			</Menu>
		)
	}

	const [modalRepair, contextHolderRepair] = Modal.useModal()

	const configRepair = {
		title: 'อยู่ระหว่างการปรับปรุง',
		content: (
			<>
				ขออภัยในความไม่สะดวก โปรดกลับมาใหม่ภายหลัง
			</>
		),
	}

	const genLeftMenu = () => {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					fontSize: 18,
					marginTop: -2
				}}
			>
				
				<div
					onClick={() => {
						// navigate("/")
						// window.scrollTo(0, 0)
						modalRepair.warning(configRepair)
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>เกี่ยวกับเรา</label>
					</div>
				</div>

				<div
					onClick={() => {
						// navigate("/")
						// window.scrollTo(0, 0)
						modalRepair.warning(configRepair)
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>บริการของเรา</label>
					</div>
				</div>

				<div
					onClick={() => {
						// navigate("/")
						// window.scrollTo(0, 0)
						modalRepair.warning(configRepair)
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>ผลงาน</label>
					</div>
				</div>

				<div
					onClick={() => {
						// navigate("/")
						// window.scrollTo(0, 0)
						modalRepair.warning(configRepair)
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>ติดต่อเรา</label>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div
			style={{
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0,
				position: 'fixed',
				zIndex: 2,
				width: "100%",
				backgroundColor: "#1E2736",
				overflow: 'hidden',
				boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
				height: 70,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between"
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					paddingLeft: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 24 : 12)
				}}
			>
				<div style={{ display: "grid", alignItems: "center", color: "white" }}>
					<img
						src={"./assets/images/logo/logo_spm_white.png"}
						alt="Logo Soft Po Market"
						style={{ width: 55, height: 55, cursor: "pointer" }}
						onClick={() => {
							// navigate("/")
						}}
					/>
				</div>

				<div>
					<label style={{ fontSize: 22, color: "white", paddingLeft: 12 }}>Soft Po Market</label>
				</div>
			</div>

			{width > 1012 ?
				<div
					style={{
						display: "flex",
						paddingTop: 2,
						alignItems: "center",
						justifyContent: "flex-end",
						paddingRight: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 24 : 12)
					}}
				>
					{genLeftMenu()}
				</div>
				:
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
					<Drawer
						title={<label style={{ fontSize: 16, fontWeight: 100 }}>Kingdom Store</label>} placement="right"
						closable={false}
						onClose={onClose}
						visible={state.visible}
						bodyStyle={{ padding: '0px' }}
						size="default"
					>
						{genRightMenu()}
					</Drawer>

					<Button
						type="primary"
						onClick={showDrawer}
						style={{
							height: 70,
							width: 70,
							borderRadius: 0
						}}
					>
						<Icon
							icon="charm:menu-hamburger"
							style={{
								fontSize: '2.5em',
								marginTop: 4,
								color: "white"
							}}
						/>
					</Button>
				</div>
			}
			{contextHolderRepair}
		</div>
	)
}