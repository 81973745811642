/* eslint-disable no-useless-concat */
import { httpClient } from "../../../utils/HttpClient"

const getReportCustomerAuthSignInFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.post("client_auth/get-report-customer-auth-sign-in", body)
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getCaseSearchClientFetch = async (value) => {
    try {
        const result = await httpClient.post("case/get-case-search-client", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCaseTypeAllFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.get("category/get-case-type-all")
        if (result.data.isSuccess) {
            return result.data.formData
        }
    } catch (err) { // status 404
        return null
    }
}

const getCaseSubtypeAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-case-subtype-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCaseProcessAllFetch = async (value) => {
    try {
        const result = await httpClient.post("category/get-case-process-all", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDetectOpenCaseFetch = async (value) => {
    try {
        const result = await httpClient.post("case/get-detect-open-case", value)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertCaseFetch = async (value) => {
    try {
        const result = await httpClient.post("case/insert-case", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertCaseImageFetch = async (value) => {
    try {
        const result = await httpClient.post("case/insert-case-image", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertCaseVideoFetch = async (value) => {
    try {
        const result = await httpClient.post("case/insert-case-video", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertCaseFileFetch = async (value) => {
    try {
        const result = await httpClient.post("case/insert-case-file", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCaseByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("case/update-case-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteCaseByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("case/delete-case-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteCaseImageByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("case/delete-case-image-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteCaseVideoByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("case/delete-case-video-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteCaseFileByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("case/delete-case-file-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getReportCustomerAuthSignInFetch,
    getCaseSearchClientFetch,
    getCaseTypeAllFetch,
    getCaseSubtypeAllFetch,
    getCaseProcessAllFetch,
    getDetectOpenCaseFetch,

    // insert
    insertCaseFetch,
    insertCaseImageFetch,
    insertCaseVideoFetch,
    insertCaseFileFetch,

    // update
    updateCaseByIdFetch,

    // delete
    deleteCaseByIdFetch,
    deleteCaseImageByIdFetch,
    deleteCaseVideoByIdFetch,
    deleteCaseFileByIdFetch
}