import "./styles/App.css"
import "./styles/App.less"

import { ConfigProvider } from 'antd'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"
import themeAntd from './styles/themeAntd'
import Login from "./modules/report/login"
import OpenCase from "./modules/report/openCase"

const App = ({ authorized, firebase, user }) => (
    <ConfigProvider theme={{ token: themeAntd }}>
        <Router>
            <Routes>
                <Route exact path="/report" Component={() => (<Login/>)} />
                <Route exact path="/open-case" Component={() => (<OpenCase/>)} />
                <Route path="/:id">Page not found!</Route>
            </Routes>
        </Router>
    </ConfigProvider>
)

export default App
