/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"
import { Row, Col, Button, Form, Input, Select, Image } from "antd"
import { Icon } from "@iconify/react"
import "../../css/index.css"
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import { getCaseTypeAllFetch, getReportCustomerAuthSignInFetch } from "../../API"
import { Notification } from "../../../../common/components/notification"
import { useNavigate } from "react-router"

const { Option } = Select;

export default function LoginInfo() {
    const [form] = Form.useForm()
    const { width } = useDimensions()

    const [caseType, setCaseType] = useState([])

    const [isCorrect, setIsCorrect] = useState(null)

    const navigate = useNavigate()

    const onFinish = async (values) => {
        let body = {
            uid: values.uid.trim() ?? null,
            domainName: values.domainName.trim() ?? null
        }

        const result = await getReportCustomerAuthSignInFetch(null, body, null)
        // console.log("getReportCustomerAuthSignInFetch : ", result)

        if (result?.isSuccess) {
            if (result?.formData?.isCorrect) {
                setIsCorrect(true)

                let obj = {
                    uid: result.formData.uid,
                    customerId: result.formData.customerId,
                    projectId: result.formData.projectId,
                    caseTypeId: values.caseType ?? null
                }
                // console.log("obj : ", obj)
                
                sessionStorage.setItem('auth', JSON.stringify(obj))

                navigate("/open-case")
            } else {
                setIsCorrect(false)
            }
        } else {
            Notification('error', 'มีปัญหาในการเข้าใช้งาน โปรดติดต่อผู้เกี่ยวข้อง!')
        }
    }

    const getCaseTypeAll = async () => {
        const result = await getCaseTypeAllFetch(null, null, null)
        console.log("getCaseTypeAllFetch : ", result)
        setCaseType(result)
    }

    const getBaseApi = async () => {
        await getCaseTypeAll()
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <div
                style={{
                    background: "linear-gradient(to bottom, #19212E, #263144)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop:
                            width > 1200 ? 150 : width > 768 && width < 1200 ? 150 : 94,
                        paddingBottom: width > 768 ? 82 : 24,
                        maxWidth: 1000
                    }}
                >
                    <Row gutter={[0, 0]}>
                        <Col xs={24} md={12} xl={12}>
                            <div style={{ color: "white", display: "grid", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ paddingBottom: 10 }}>
                                    <label style={{ fontSize: 24 }}>ส่งทุกความต้องการมาให้เรา พร้อมช่วยเหลือ</label>
                                </div>
                                <div style={{ paddingBottom: 10, display: "flex", alignItems: "center" }}>
                                    <img src={"./assets/images/icon/check-mark.png"} style={{ width: 30, height: 30 }} />
                                    <label style={{ fontSize: 16, paddingLeft: 8 }}>เเจ้งปัญหาการใช้งานเว็บไซต์</label>
                                </div>
                                <div style={{ paddingBottom: 10, display: "flex", alignItems: "center" }}>
                                    <img src={"./assets/images/icon/check-mark.png"} style={{ width: 30, height: 30 }} />
                                    <label style={{ fontSize: 16, paddingLeft: 8 }}>เเจ้งเปลี่ยนเเปลงข้อมูลเว็บไซต์</label>
                                </div>
                                <div style={{ paddingBottom: 10, display: "flex", alignItems: "center" }}>
                                    <img src={"./assets/images/icon/check-mark.png"} style={{ width: 30, height: 30 }} />
                                    <label style={{ fontSize: 16, paddingLeft: 8 }}>เเจ้งเพิ่มฟังก์ชั่นการใช้งานในเว็บไซต์</label>
                                </div>
                                <div style={{ paddingBottom: 20, display: "flex", alignItems: "center" }}>
                                    <img src={"./assets/images/icon/check-mark.png"} style={{ width: 30, height: 30 }} />
                                    <label style={{ fontSize: 16, paddingLeft: 8 }}>เเจ้งลงข้อมูลรูปภาพ หรือเนื้อหาในเว็บไซต์</label>
                                </div>
                                <center>
                                    <img src={"./assets/images/report/problem.png"} style={{ width: 200 }} />
                                </center>
                            </div>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <div>
                                <div
                                    style={{
                                        backgroundColor: "white",
                                        borderRadius: 16,
                                        paddingRight: 24,
                                        paddingLeft: 24,
                                        paddingTop: 18,
                                        paddingBottom: 18,
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div style={{ width: "100%" }}>
                                        <Form layout="vertical" form={form} onFinish={onFinish}>
                                            <Row gutter={[24, 4]}>
                                                <Col span={24}>
                                                    <div style={{ paddingBottom: 8 }}>
                                                        <label style={{ fontSize: 18 }}>
                                                            เข้าสู่ระบบ เพื่อเเจ้งปัญหา ปรับเเต่ง หรือเพิ่มข้อมูลเว็บไซต์
                                                        </label>
                                                    </div>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item
                                                        name="uid"
                                                        rules={[{ required: true, message: "กรุณากรอกรหัสผู้ใช้" }]}
                                                    >
                                                        <Input
                                                            placeholder="กรอกรหัสผู้ใช้"
                                                            style={{ width: "100%" }}
                                                            size="large"
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item
                                                        name="domainName"
                                                        rules={[{ required: true, message: "กรุณากรอกชื่อเว็บไซต์ของคุณ เช่น a.com" }]}
                                                    >
                                                        <Input
                                                            placeholder="กรอกชื่อเว็บไซต์ของคุณ เช่น a.com"
                                                            style={{ width: "100%" }}
                                                            size="large"
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                {/* <Col span={24}>
                                                    <Form.Item
                                                        name="caseType"
                                                        rules={[{ required: true, message: "กรุณาเลือกประเภทการเเจ้ง" }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            placeholder="ประเภทการเเจ้ง"
                                                            style={{ width: "100%" }}
                                                            optionFilterProp="children"
                                                            allowClear
                                                            size="large"
                                                        >
                                                            {caseType?.map((item, index) => {
                                                                return (
                                                                    <Option key={index} value={item?.caseTypeId}>
                                                                        {item?.name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col> */}

                                                <Col span={24}>
                                                    <Button
                                                        type="primary"
                                                        size="large"
                                                        style={{ width: "100%" }}
                                                        onClick={() => {
                                                            window.scroll(0, 0)
                                                            form.submit()
                                                        }}
                                                    >
                                                        <label
                                                            // className="ks-item-title"
                                                            style={{ cursor: "pointer", fontSize: 18 }}
                                                        >
                                                            คลิก! เข้าสู่ระบบ
                                                        </label>
                                                    </Button>
                                                </Col>

                                                {isCorrect === false ?
                                                    <Col span={24}>
                                                        <div style={{ display: "grid", justifyContent: "center", paddingTop: 12 }}>
                                                            <label style={{ fontSize: 18, color: "red" }}>ข้อมูลไม่ถูกต้อง โปรดลองใหม่อีกครั้ง!</label>
                                                        </div>
                                                    </Col>
                                                    : []
                                                }
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
