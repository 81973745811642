/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Image, Input, Modal, Pagination, Popconfirm, Row, Select, Spin, Steps, Table, theme, Upload } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Icon } from '@iconify/react'
import { FaCog } from "react-icons/fa"

import {
    deleteCaseByIdFetch,
    deleteCaseFileByIdFetch,
    deleteCaseImageByIdFetch,
    deleteCaseVideoByIdFetch,
    getCaseProcessAllFetch,
    getCaseSearchClientFetch,
    getCaseSubtypeAllFetch,
    getCaseTypeAllFetch,
    getDetectOpenCaseFetch,
    insertCaseFetch,
    insertCaseFileFetch,
    insertCaseImageFetch,
    insertCaseVideoFetch,
    updateCaseByIdFetch
} from '../../API'
import parse from 'html-react-parser'
import { Notification } from '../../../../common/components/notification'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
    LoadingOutlined,
    UploadOutlined
} from '@ant-design/icons'
import Video from '../../../../common/components/video'
import { apiServerUrl } from '../../../../constants'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import moment from 'moment'
import 'moment/locale/th'; // import ภาษาไทย

moment.locale('th'); // ตั้งค่า locale เป็นไทย

const { Column } = Table
const { Option } = Select

const formatDate = "DD/MM/YYYY"
const formatDateTime = "DD/MM/YYYY HH:mm:ss"

export default function CaseFrom() {

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const auth = JSON.parse(sessionStorage.getItem('auth'))
    console.log("auth : ", auth)

    const [loading, setLoading] = useState(true)

    const [list, setList] = useState([])

    const { width } = useDimensions()

    const [caseType, setCaseType] = useState(null)
    const [caseSubtype, setCaseSubtype] = useState(null)
    // const [caseProcess, setCaseProcess] = useState(null)
    const caseProcessRef = useRef([])

    const [casesImage, setCasesImage] = useState([])
    const [casesVideo, setCasesVideo] = useState([])
    const [casesFile, setCasesFile] = useState([])

    const [caseImageLoading, setCaseImageLoading] = useState(false)
    const [caseVideoLoading, setCaseVideoLoading] = useState(false)
    const [caseFileLoading, setCaseFileLoading] = useState(false)

    const [caseImageURL, setCaseImageURL] = useState({
        loading: false,
        imageUrl: null
    })
    const [caseVideoURL, setCaseVideoURL] = useState({
        loading: false,
        imageUrl: null
    })
    const [caseFileURL, setCaseFileURL] = useState({
        loading: false,
        fileUrl: null
    })

    const caseSearchRef = useRef({
        name: null,
        customerId: null,
        projectId: null,
        caseProcessId: null
    })

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const [modalCaseInfo, setModalCaseInfo] = useState({
        isShow: false,
        title: null,
        data: null
    })

    const steps = [
        {
            title: 'รายละเอียด Case',
            content: 'step-case-info',
        },
        {
            title: 'อัพโหลดไฟล์ (รูปภาพ, วิดีโอ)',
            content: 'step-case-file',
        }
    ]

    const { token } = theme.useToken()
    const [current, setCurrent] = useState(0)
    const isHandleInsertRef = useRef(false)
    const caseIdRef = useRef(null)
    const callbackUpdateCaseRef = useRef(false)

    const openCaseInfoRef = useRef(null)

    const objFromRef = useRef({
        caseId: null,
        customer: null,
        project: null,
        caseProcess: null,
        priority: null,
        caseType: null,
        caseSubtype: null,
        name: null,
        description: null,
        operatingExpenses: null,
        completionDate: null,
        isActive: 1
    })

    const optionCaseImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "spm-erp",
            folder: "case/image"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    setCaseImageURL({
                        imageUrl: result.url,
                        loading: false
                    })
                }
            } else {
                setCaseImageURL({
                    imageUrl: caseImageURL.imageUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                setCaseImageLoading(true)

                let objServer = {
                    caseId: caseIdRef.current,
                    url: result.url,
                    name: null,
                    description: null,
                }
                const resultInsertCaseImage = await insertCaseImageFetch(objServer)
                if (resultInsertCaseImage?.isSuccess) {
                    Notification('success', 'Upload สำเร็จ!')
                    let objClient = {
                        caseId: caseIdRef.current,
                        caseImageId: resultInsertCaseImage.formData.caseImageId,
                        url: result.url,
                        name: null,
                        description: null,
                    }
                    let tmpCasesImage = casesImage
                    tmpCasesImage?.push(objClient)
                    setCasesImage(tmpCasesImage)
                } else {
                    Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
                }

                setCaseImageLoading(false)
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionCaseVideo = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "spm-erp",
            folder: "case/video"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    setCaseVideoURL({
                        videoUrl: result.url,
                        loading: false
                    })
                }
            } else {
                setCaseVideoURL({
                    videoUrl: caseVideoURL.videoUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                setCaseVideoLoading(true)

                Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ")
                let objServer = {
                    caseId: caseIdRef.current,
                    url: result.url,
                    name: null,
                    description: null,
                }
                const resultInsertCaseVideo = await insertCaseVideoFetch(objServer)
                if (resultInsertCaseVideo?.isSuccess) {
                    Notification('success', 'Upload สำเร็จ!')
                    let objClient = {
                        caseId: caseIdRef.current,
                        caseVideoId: resultInsertCaseVideo.formData.caseVideoId,
                        url: result.url,
                        name: null,
                        description: null,
                    }
                    let tmpCasesVideo = casesVideo
                    tmpCasesVideo?.push(objClient)
                    setCasesVideo(tmpCasesVideo)
                } else {
                    Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
                }

                setCaseVideoLoading(false)
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionCaseFile = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "spm-erp",
            folder: "case/file"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    setCaseFileURL({
                        fileUrl: result.url,
                        loading: false
                    })
                }
            } else {
                setCaseFileURL({
                    fileUrl: caseFileURL.fileUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                setCaseFileLoading(true)

                Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ")
                let objServer = {
                    caseId: caseIdRef.current,
                    url: result.url,
                    name: result.name,
                    description: null
                }
                const resultInsertCaseFile = await insertCaseFileFetch(objServer)
                if (resultInsertCaseFile?.isSuccess) {
                    Notification('success', 'Upload สำเร็จ!')
                    let objClient = {
                        caseId: caseIdRef.current,
                        caseFileId: resultInsertCaseFile.formData.caseFileId,
                        url: result.url,
                        name: result.name,
                        description: null
                    }
                    let tmpCasesFile = casesFile
                    tmpCasesFile?.push(objClient)
                    setCasesFile(tmpCasesFile)
                } else {
                    Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
                }

                setCaseFileLoading(false)
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00'
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`
        }
    }

    const size = 3

    const [page, setPage] = useState({
        minValue: 0,
        maxValue: size
    })

    const handleChange = value => {
        if (value <= 1) {
            setPage({
                minValue: 0,
                maxValue: size
            });
        } else {
            setPage({
                minValue: (value - 1) * size,
                maxValue: value * size
            })
        }
    }

    const next = async () => {
        if (modal.title === "add" && isHandleInsertRef.current === false) {
            form.submit()
        } else if (modal.title === "edit") {
            objFromRef.current = form.getFieldsValue()
        }
        setCurrent(current + 1)
    }
    const prev = () => {
        setCurrent(current - 1)
    }
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title
    }))
    const contentStyle = {
        // color: token.colorTextTertiary,
        // backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
        padding: 16
    }

    const detectOpenCase = async (customerId, projectId) => {

        if (customerId && projectId) {
            let result = await getDetectOpenCaseFetch({ customerId, projectId })
            if (!result?.isCanOpenCase) { // Over limit
                Notification(
                    'error',
                    ('Case ที่เปิดดำเนินการอยู่นี้เกิน Limit สูงสุด ' + result?.maxOpenCase + 'Case ต่อครั้งเเล้ว จึงไม่สามารถเปิดเคสนี้เพิ่มได้ โปรดรอเคียร์ Case ก่อนหน้าเหล่านั้นให้เเล้วเสร็จก่อน!')
                )
            }
            openCaseInfoRef.current = result
            return result
        } else {
            return {
                isCanOpenCase: true,
                amountCase: 0,
                list: []
            }
        }
    }

    const getCaseBySearch = async (search) => {
        setLoading(true)

        const result = await getCaseSearchClientFetch(search)
        // console.log("getCaseSearchClientFetch : ", result)

        let tempList = [];
        result?.map((val, index) => {

            let caseProcessModeCustomer = {
                name: null,
                caseProcessId: null
            }
            if (val?.caseProcessId === 4 || val?.caseProcessId === 7) {
                const fill = caseProcessRef.current?.filter(val => val.caseProcessId === 3)[0]
                caseProcessModeCustomer = {
                    name: fill?.name + ` (MC-ID:${val?.caseProcessId})`, // mode customer id : caseProcessId
                    caseProcessId: fill?.caseProcessId
                }
            } else {
                caseProcessModeCustomer = {
                    name: val?.case_process?.name,
                    caseProcessId: val?.caseProcessId
                }
            }

            tempList.push({
                caseId: val.caseId,
                caseCode: val.caseCode,
                name: val?.name ?? "-",
                description: val?.description ?? "-",
                project: val?.project?.name ?? "-",
                owner: val?.employee?.firstname ?? "-",
                customer: val?.customer?.uid ?? "-",
                caseProcess: caseProcessModeCustomer?.name ?? "-",
                caseProcessId: caseProcessModeCustomer?.caseProcessId,
                priority: val?.priority?.name ?? "-",
                completionDate: val?.completionDate ? moment(val.completionDate).format("DD MMMM YYYY") : "-",
                operationCost: val?.operationCost,
                caseImage: val?.case_images,
                caseVideo: val?.case_videos,
                caseFile: val?.case_files,
                caseType: val?.case_type?.name ?? "-",
                projectPhase: val?.project_phase ? `${val.project_phase.name} (Phase/Round ${val.project_phase.phase})` : "-",
                isActive: val?.isActive,
                createdAt: val?.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val?.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 28,
                                padding: 0,
                                backgroundColor: "#0066ff",
                                border: "1px solid #0066ff",
                                color: 'white',
                                // borderRadius: 50
                            }}
                            onClick={async () => {
                                setModalCaseInfo({
                                    isShow: true,
                                    title: "รายละเอียด Case " + val.name,
                                    data: val
                                })
                            }}
                            size="small"
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="fluent:document-data-20-regular" style={{ color: "white", width: 15, height: 15 }} />
                            </div>
                        </Button>

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleCaseDelete(val.caseId)

                                // reload
                                await getCaseBySearch({
                                    name: caseSearchRef.current.name,
                                    projectId: caseSearchRef.current.projectId,
                                    caseProcessId: caseSearchRef.current.caseProcessId,
                                    customerId: auth?.customerId ?? null,
                                    priorityId: null,
                                    startedDate: null,
                                    endDate: null
                                })
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 28,
                                    padding: 0,
                                    // borderRadius: 50
                                }}
                                size="small"
                            >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 15, height: 15 }} />
                                </div>
                            </Button>{"  "}
                        </Popconfirm>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const genBodyApi = (data, caseId) => {
        let obj = {
            caseId: caseId ?? null, // ***
            customerId: auth?.customerId ?? null, //
            projectId: auth?.projectId ?? null, //
            caseProcessId: 1, //
            ownerId: null, //
            priorityId: null, //
            caseTypeId: auth?.caseTypeId, //
            caseSubtypeId: null, // ###
            name: data.name ?? null,
            description: data.description ?? null,
            employeeExpenses: null,
            operatingExpenses: null, // ###
            completionDate: null,
            isActive: data.isActive ?? null,
        }
        return obj
    }

    const onFinish = async (values) => {
        if (modal.title === "add" && !callbackUpdateCaseRef.current) { // Step 1 [Add]
            let obj = genBodyApi(values, values.caseId)
            // console.log("obj - A : ", obj)
            const result = await insertCaseFetch(obj)
            if (result?.isSuccess) {
                caseIdRef.current = result?.formData?.caseId
                isHandleInsertRef.current = true
            } else {
                Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit" || callbackUpdateCaseRef.current) {
            let obj = genBodyApi(objFromRef.current, caseIdRef.current)
            if (modal.title === "add" && callbackUpdateCaseRef.current) { // Step 2 [Add] : Edit form add by previous
                const result = await updateCaseByIdFetch(obj)
                // console.log("obj - B : ", obj)
                if (result.isSuccess) {
                    Notification('success', 'สร้างสำเร็จ')
                } else {
                    Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
                }

                // set default
                setFormDefault()
            } else { // edit original
                const result = await updateCaseByIdFetch(obj)
                // console.log("obj - C : ", obj)
                if (result.isSuccess) {
                    Notification('success', 'สร้างสำเร็จ')
                } else {
                    Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
                }

                // set default
                setFormDefault()
            }
        }

        // reload
        getCaseBySearch({
            name: caseSearchRef.current.name,
            projectId: caseSearchRef.current.projectId,
            caseProcessId: caseSearchRef.current.caseProcessId,
            customerId: auth?.customerId ?? null,
            priorityId: null,
            startedDate: null,
            endDate: null
        })
    }

    const onSearchFinish = async (values) => {
        let name = values?.name ?? null
        let projectId = values?.project ?? null
        let caseProcessId = values?.caseProcess ?? null
        let customerId = auth.customerId ?? null
        let startedDate = null // values?.dateRange ? values.dateRange[0] : null
        let endDate = null // values?.dateRange ? values.dateRange[1] : null

        let objSearch = {
            name,
            projectId,
            caseProcessId,
            customerId,
            priorityId: null,
            startedDate,
            endDate
        }
        // console.log("onSearchFinish : ", objSearch)

        caseSearchRef.current = {
            name,
            customerId,
            projectId,
            caseProcessId
        }

        await getCaseBySearch(objSearch)
    }

    const handleCaseDelete = async (id) => {
        const result = await deleteCaseByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleCaseImageDelete = async (id) => {
        const result = await deleteCaseImageByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบรูปภาพสำเร็จ')

            let tmpCasesImage = casesImage.filter(fill => fill.caseImageId !== id)
            setCasesImage(tmpCasesImage)
        } else {
            Notification('error', 'ไม่สามารถลบรูปภาพได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleCaseVideoDelete = async (id) => {
        const result = await deleteCaseVideoByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบวิดีโอสำเร็จ')

            let tmpCasesVideo = casesVideo.filter(fill => fill.caseVideoId !== id)
            setCasesVideo(tmpCasesVideo)
        } else {
            Notification('error', 'ไม่สามารถลบวิดีโอได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleCaseFileDelete = async (id) => {
        const result = await deleteCaseFileByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบวิดีโอสำเร็จ')

            let tmpCasesFile = casesFile.filter(fill => fill.caseFileId !== id)
            setCasesFile(tmpCasesFile)
        } else {
            Notification('error', 'ไม่สามารถลบวิดีโอได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const setFormDefault = () => {
        form.resetFields()
        setModal({ isShow: false, title: null })
    }

    const caseProcessColor = (id) => {
        switch (id) {
            case 1:
                return "#ed9600"
            case 2:
                return "#00aaff"
            case 3:
                return "#ed9600"
            case 4:
                return "#a100e8"
            case 5:
                return "#429321"
            case 6:
                return "#c0c0c0"
            case 7:
                return "#ff0000"
            default:
                break
        }
    }

    function maskString(input) {
        if (input.length <= 3) {
            return input; // ถ้าความยาวของสตริงน้อยกว่าหรือเท่ากับ 3 ให้แสดงทั้งหมด
        }
        let masked = '*'.repeat(input.length - 3); // แทนที่ตัวอักษรด้วย * ยกเว้น 3 ตัวท้าย
        return masked + input.slice(-3); // แสดง 3 ตัวท้าย
    }

    const genCardCase = (data) => {
        return (
            <div
                style={{
                    backgroundColor: "white",
                    // padding: 24,
                    // border: "2px solid #EEEEEE",
                    borderRadius: 6,
                    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.2)",
                    display: "flex"
                }}
            >
                <div style={{ width: 40, backgroundColor: caseProcessColor(data.caseProcessId), borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}></div>
                <Row
                    gutter={[0, 12]}
                    style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 10, paddingBottom: 10, width: "100%" }}
                >
                    <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <div style={{ display: "grid" }}>
                            <label>รหัส Case</label>
                            <label style={{ color: "#939393" }}>{data?.caseCode ?? "-"}</label>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={16} lg={18} xl={18}>
                        <div style={{ display: "grid" }}>
                            <label>หัวข้อ Case</label>
                            <label style={{ color: "#939393" }}>{data?.name ?? "-"}</label>
                        </div>
                    </Col>
                    {/* ------------------------------------------------------------------ */}
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <div style={{ display: "grid" }}>
                            <label>โปรเจกต์</label>
                            <label style={{ color: "#939393" }}>{data?.project ?? "-"}</label>
                        </div>
                    </Col>
                    {/* ------------------------------------------------------------------ */}
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <div style={{ display: "grid" }}>
                            <label>ดำเนินการ</label>
                            <label style={{ color: caseProcessColor(data.caseProcessId) }}>{data?.caseProcess ?? "-"}</label>
                        </div>
                    </Col>
                    {/* ------------------------------------------------------------------ */}
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <div style={{ display: "grid" }}>
                            <label>รหัสผู้ใช้</label>
                            <label style={{ color: "#939393" }}>{data?.customer ? maskString(data?.customer) : "-"}</label>
                        </div>
                    </Col>
                    {/* ------------------------------------------------------------------ */}
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <div style={{ display: "grid" }}>
                            <label>วันที่สร้าง</label>
                            <label style={{ color: "#939393" }}>{data?.createdAt ?? "-"}</label>
                        </div>
                    </Col>
                    {/* ------------------------------------------------------------------ */}
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <div style={{ display: "grid" }}>
                            <label>คาดว่าจะเเล้วเสร็จ</label>
                            <label style={{ color: "#939393" }}>{data?.completionDate ?? "-"}</label>
                        </div>
                    </Col>
                </Row>
                <div style={{ float: "right", padding: 12 }}>
                    <Button
                        style={{ width: 38, padding: 0, color: 'white' }}
                        onClick={async () => {
                            setModalCaseInfo({
                                isShow: true,
                                title: "รายละเอียด Case " + (data?.name ?? "-"),
                                data: (data ?? null)
                            })
                        }}
                        type="primary"
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Icon icon="fluent:document-data-20-regular" style={{ color: "white", width: 25, height: 25 }} />
                        </div>
                    </Button>
                </div>
            </div>
        )
    }

    const getCaseTypeAll = async () => {
        const result = await getCaseTypeAllFetch()
        // console.log("getCaseTypeAllFetch : ", result)
        setCaseType(result)
    }

    // const getCaseSubtypeAll = async () => {
    //     const result = await getCaseSubtypeAllFetch()
    //     // console.log("getCaseSubtypeAllFetch : ", result)
    //     setCaseSubtype(result)
    // }

    const getCaseProcessAll = async () => {
        let obj = {
            isPermitAll: true
        }
        const result = await getCaseProcessAllFetch(obj)
        const filteredData = result.filter(item => item.caseProcessId >= 1 && item.caseProcessId <= 5);
        // console.log("getCaseProcessAllFetch : ", result)
        caseProcessRef.current = filteredData
    }

    const getBaseApi = async () => {
        // await getCaseSubtypeAll()
        await getCaseProcessAll()
        await detectOpenCase()
        await getCaseTypeAll()

        await getCaseBySearch({
            name: caseSearchRef.current.name,
            projectId: caseSearchRef.current.projectId,
            caseProcessId: caseSearchRef.current.caseProcessId,
            customerId: auth?.customerId ?? null,
            priorityId: null,
            startedDate: null,
            endDate: null
        })
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    if (!auth) {
        return null
    }
    
    return (
        <div
            style={{
                paddingLeft: (width < 1000 ? 12 : 0),
                paddingRight: (width < 1000 ? 12 : 0)
            }}
        >
            <Row>
                <Col span={24}>
                    <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                        <div style={{ display: (width > 768 ? "flex" : "grid"), justifyContent: (width > 768 ? "space-between" : "center") }}>
                            {openCaseInfoRef?.current?.amountCase > 0 ?
                                <div style={{ paddingBottom: 12, width: "100%" }}>
                                    จำนวน {openCaseInfoRef?.current?.amountCase ?? 0} Case ที่เปิดดำเนินการอยู่
                                </div>
                                : []
                            }

                            <div
                                style={{
                                    display: (width > 768 ? "flex" : "grid"),
                                    alignItems: "center",
                                    justifyContent: (width > 768 ? "flex-end" : "center"),
                                    paddingBottom: (width > 768 ? 0 : 24),
                                    width: "100%"
                                }}
                            >
                                <div style={{ paddingLeft: 10 }}>
                                    <Form.Item
                                        // label="ชื่อหัวข้อ Case"
                                        name="name"
                                        style={{ width: (width > 768 ? 250 : "100%") }}
                                    >
                                        <Input placeholder="ชื่อหัวข้อ Case"/>
                                    </Form.Item>
                                </div>

                                <div style={{ paddingLeft: 10 }}>
                                    <Form.Item
                                        name="caseProcess"
                                        // label="การดำเนิน Case"
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: (width > 768 ? 250 : "100%") }}
                                            optionFilterProp="children"
                                            allowClear
                                            placeholder="การดำเนิน Case"
                                        >
                                            {caseProcessRef.current?.map((item, index) => (
                                                <Option key={index} value={item.caseProcessId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div style={{ paddingLeft: 10, marginTop: -24 }}>
                                    <Button
                                        style={{ float: 'right', width: (width > 768 ? "auto" : "100%") }}
                                        type="primary"
                                        onClick={() => formSearch.submit()}
                                    >
                                        ค้นหา
                                    </Button>
                                </div>

                                <div style={{ paddingLeft: 10, marginTop: -24 }}>
                                    <Button
                                        type="primary"
                                        style={{ float: 'right', width: (width > 768 ? "auto" : "100%") }}
                                        onClick={async () => {

                                            let result = await detectOpenCase(auth.customerId, auth.projectId)
                                            // console.log("result --- : ", result)
                                            if (result?.isCanOpenCase) {
                                                form.resetFields()
                                                // form.setFieldsValue({
                                                //     caseType: auth.caseTypeId
                                                // })
                                                isHandleInsertRef.current = false
                                                callbackUpdateCaseRef.current = false
                                                setCurrent(0)
                                                setCasesImage([])
                                                setCasesVideo([])
                                                setCasesFile([])

                                                setModal({ isShow: true, title: "add" })
                                            }
                                        }}
                                    >เปิด Case</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Col>

                {!loading ? 
                <>
                    {list
                        ?.slice(page.minValue, page.maxValue)
                        ?.map((item, index) => {
                            return (
                                <Col key={index} span={24}>
                                    <div style={{ paddingBottom: 12 }}>
                                        {genCardCase(item)}
                                    </div>
                                </Col>
                            )
                        })
                    }

                    {list?.length === 0 ?
                        <Col span={24}>
                            <div
                                style={{
                                    padding: 24,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    backgroundColor: "#EEEEEE",
                                    borderRadius: 6
                                }}
                            >
                                <label>ไม่มี Case</label>
                            </div>
                        </Col>
                        : []
                    }

                    {list?.length > size ?
                        <Col span={24}>
                            <div style={{ paddingTop: 12 }}>
                                <center>
                                    <Pagination
                                        defaultCurrent={1}
                                        defaultPageSize={size}
                                        onChange={handleChange}
                                        total={list?.length ?? 0}
                                    />
                                </center>
                            </div>
                        </Col>
                        : []
                    }
                </>
                :
                <div style={{ width: "100%", paddingTop: 48, paddingBottom: 48, display: "grid", alignItems: "center", justifyContent: "center" }}>
                    <Spin size="large" />
                    <label style={{ paddingTop: 12 }}>กำลังดาวน์โหลด ...</label>
                </div>
}
            </Row>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่ม Case" : "เเก้ไข Case"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault()
                }}
                style={{ top: 8 }}
                width={"100%"}
                footer={[
                    current > 0 && (
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            ย้อนกลับ
                        </Button>
                    ),
                    current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            ถัดไป
                        </Button>
                    ),
                    current === steps.length - 1 && (
                        <Button
                            type="primary"
                            onClick={() => {
                                if (caseIdRef.current) {
                                    callbackUpdateCaseRef.current = true
                                    form.submit()
                                }
                            }}
                        >
                            ยืนยัน
                        </Button>
                    )
                ]}
            >
                <Steps current={current} items={items} />
                <div style={contentStyle}>
                    {steps[current].content === "step-case-info" ?
                        <Form
                            layout="vertical"
                            form={form}
                            onFinish={onFinish}
                        >
                            <Row gutter={[24, 0]}>
                                <Col span={24}>
                                    <Form.Item name="caseId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="name"
                                        label="หัวข้อ Case"
                                        rules={[{ required: true, message: 'กรุณากรอกหัวข้อ Case' }]}
                                    >
                                        <Input onChange={(e) => objFromRef.current.name = e.target.value} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="description"
                                        label="คำอธิบาย อย่างละเอียด"
                                        rules={[{ required: true, message: 'กรุณากรอกคำอธิบาย อย่างละเอียด' }]}
                                    >
                                        <Input style={{ display: "none" }} />
                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={{
                                                placeholder: 'พิมพ์คำอธิบายที่นี่...',
                                                toolbar: {
                                                    items: [
                                                        'heading', '|',
                                                        'fontfamily', 'fontsize', '|',
                                                        'bold', 'italic', 'underline', '|',
                                                        'alignment', '|',
                                                        'fontColor', 'fontBackgroundColor', '|',
                                                        'bulletedList', 'numberedList', 'todoList', '|',
                                                        'code', 'codeBlock', '|',
                                                        'undo', 'redo'
                                                    ],
                                                    removeButtons: 'Subscript,Superscript'
                                                }
                                            }}
                                            data={form.getFieldValue().description ?? ""}
                                            onBlur={(event, editor) => {
                                                const data = editor.getData()
                                                form.setFieldValue('description', data)
                                                objFromRef.current.description = data
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                {/* <Col span={6}>
                                    <Form.Item
                                        name="caseProcess"
                                        label="การดำเนิน Case"
                                        rules={[{ required: true, message: 'กรุณาเลือกการดำเนิน Case' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%', }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            {caseProcess?.map((item, index) => (
                                                <Option key={index} value={item.caseProcessId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col> */}

                                <Col span={8}>
                                    <Form.Item
                                        name="caseType"
                                        label="ประเภท Case"
                                        rules={[{ required: true, message: 'กรุณาเลือก ประเภท Case' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%', }}
                                            optionFilterProp="children"
                                            allowClear
                                            // disabled={true}
                                        >
                                            {caseType?.map((item, index) => (
                                                <Option key={index} value={item.caseTypeId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {/* <Col span={6}>
                                    <Form.Item
                                        name="caseSubtype"
                                        label="ประเภท Case ย่อย"
                                        rules={[{ required: true, message: 'กรุณาเลือก ประเภท Case ย่อย' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%', }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            {caseSubtype?.map((item, index) => (
                                                <Option key={index} value={item.caseSubtypeId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col> */}

                                {/* <Col span={6}>
                                    <Form.Item
                                        name="project"
                                        label="โปรเจกต์"
                                        rules={[{ required: true, message: 'กรุณาเลือก ' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            {project?.map((item, index) => (
                                                <Option key={index} value={item.projectId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col> */}
                            </Row>
                        </Form>
                        : []
                    }

                    {steps[current].content === "step-case-file" ?
                        <Row gutter={[24, 0]}>
                            <Col span={24}>
                                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                        <label style={{ paddingLeft: 8 }}>รูปภาพเเนบ (.jpg, .jpeg, .png, .jfif, .webp) ใช้ประกอบการอธิบาย Case</label>
                                    </div>

                                    <Upload
                                        {...optionCaseImage}
                                        accept='image/jpeg, image/png, image/jfif, image/webp'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={false}
                                    >
                                        <Button
                                            type="primary"
                                            style={{ width: "100%" }}
                                            icon={caseImageURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                        >อัพโหลดรูปภาพ</Button>
                                    </Upload>
                                </div>
                                <div style={{ paddingTop: 12, paddingBottom: 24 }}>
                                    <div style={{ padding: 12, border: "2px solid #EEEEEE", borderRadius: 6 }}>
                                        <Row gutter={[12, 24]}>
                                            {!caseImageLoading ?
                                                casesImage?.map((val, index) => {
                                                    return (
                                                        <Col key={index} xs={24} md={12} xl={6}>
                                                            <div style={{ display: "flex", border: "2px solid #898989" }}>
                                                                <img
                                                                    src={`${val.url}`}
                                                                    style={{ objectFit: "cover", width: "100%", height: 200 }}
                                                                />
                                                                <div
                                                                    style={{
                                                                        zIndex: 2,
                                                                        position: "absolute",
                                                                        left: "85.5%",
                                                                        top: "3%",
                                                                        cursor: "pointer",
                                                                        backgroundColor: "white",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        padding: 6,
                                                                        borderRadius: 20,
                                                                        boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
                                                                    }}
                                                                    onClick={async () => {
                                                                        await handleCaseImageDelete(val.caseImageId)
                                                                    }}
                                                                >
                                                                    <Icon icon="fluent:delete-24-regular" style={{ color: "red", width: 20, height: 20 }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                                : []
                                            }

                                            {casesImage?.length === 0 ?
                                                <Col span={24}>
                                                    <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                        <label>ไม่มีรูปภาพ</label>
                                                    </div>
                                                </Col>
                                                : []
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                        <label style={{ paddingLeft: 8 }}>วิดีโอเเนบ (.mp4) ใช้ประกอบการอธิบาย Case</label>
                                    </div>

                                    <Upload
                                        {...optionCaseVideo}
                                        accept='.mp4'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={false}
                                    >
                                        <Button
                                            type="primary"
                                            style={{ width: "100%" }}
                                            icon={caseVideoURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                        >อัพโหลดวิดีโอ</Button>
                                    </Upload>
                                </div>
                                <div style={{ paddingTop: 12, paddingBottom: 24 }}>
                                    <div style={{ padding: 12, border: "2px solid #EEEEEE", borderRadius: 6 }}>
                                        <Row gutter={[12, 24]}>
                                            {!caseVideoLoading ?
                                                casesVideo?.map((val) => {
                                                    return (
                                                        <Col xs={24} md={12} xl={12}>
                                                            <div style={{ display: "flex" }}>
                                                                <Video url={val.url} title={""} height={""} />
                                                                <div
                                                                    style={{
                                                                        zIndex: 2,
                                                                        position: "absolute",
                                                                        left: "93%",
                                                                        top: "2%",
                                                                        cursor: "pointer",
                                                                        backgroundColor: "white",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        padding: 6,
                                                                        borderRadius: 20,
                                                                        boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
                                                                    }}
                                                                    onClick={async () => {
                                                                        await handleCaseVideoDelete(val.caseVideoId)
                                                                    }}
                                                                >
                                                                    <Icon icon="fluent:delete-24-regular" style={{ color: "red", width: 20, height: 20 }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                                : []
                                            }

                                            {casesVideo?.length === 0 ?
                                                <Col span={24}>
                                                    <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                        <label>ไม่มีวิดีโอ</label>
                                                    </div>
                                                </Col>
                                                : []
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                        <label style={{ paddingLeft: 8 }}>ไฟล์เเนบ (.pdf, .docx, .xlsx, .csv) ใช้ประกอบการอธิบาย Case</label>
                                    </div>

                                    <Upload
                                        {...optionCaseFile}
                                        accept='.pdf, .docx, .xlsx, .csv'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={false}
                                    >
                                        <Button
                                            type="primary"
                                            style={{ width: "100%" }}
                                            icon={caseFileURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                        >อัพโหลดไฟล์</Button>
                                    </Upload>
                                </div>
                                <div style={{ paddingTop: 12, paddingBottom: 24 }}>
                                    <div style={{ padding: 12, border: "2px solid #EEEEEE", borderRadius: 6 }}>
                                        <Row gutter={[12, 24]}>
                                            {!caseFileLoading ?
                                                casesFile?.map((val) => {
                                                    return (
                                                        <Col xs={24} md={24} xl={24}>
                                                            <div style={{ display: "flex", border: "2px solid #EEEEEE", borderRadius: 6, padding: 12 }}>
                                                                <div style={{ display: "flex", cursor: "pointer" }}>
                                                                    <Icon icon="fa:file" style={{ width: 70, height: 70, color: "#429321" }} />
                                                                    <label style={{ paddingLeft: 6, paddingRight: 30, cursor: "pointer" }}>
                                                                        {val.name}
                                                                    </label>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        zIndex: 2,
                                                                        position: "absolute",
                                                                        left: "95%",
                                                                        top: "10%",
                                                                        cursor: "pointer",
                                                                        backgroundColor: "white",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        padding: 6,
                                                                        borderRadius: 20,
                                                                        boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
                                                                    }}
                                                                    onClick={async () => {
                                                                        await handleCaseFileDelete(val.caseFileId)
                                                                    }}
                                                                >
                                                                    <Icon icon="fluent:delete-24-regular" style={{ color: "red", width: 20, height: 20 }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                                : []
                                            }

                                            {casesFile?.length === 0 ?
                                                <Col span={24}>
                                                    <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                        <label>ไม่มีไฟล์</label>
                                                    </div>
                                                </Col>
                                                : []
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        : []
                    }
                </div>
            </Modal>

            <Modal
                title={<strong><label className="topic-color-bold">{modalCaseInfo.title}</label></strong>}
                open={modalCaseInfo.isShow}
                style={{ top: 8 }}
                onCancel={() => {
                    setModalCaseInfo({
                        isShow: false,
                        title: null,
                        data: null
                    })
                }}
                width={"100%"}
                footer={null}
            >
                <div style={{ display: (width > 768 ? "flex" : "grid") }}>
                    <div style={{ width: "100%", paddingBottom: 24 }}>
                        <Row gutter={[0, 12]}>
                            <Col span={24}>
                                <div style={{ display: "grid" }}>
                                    <label>หัวข้อ Case</label>
                                    <label style={{ color: "#939393" }}>{modalCaseInfo?.data?.name ?? "-"}</label>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div style={{ display: "grid" }}>
                                    <label>คำอธิบาย อย่างละเอียด</label>
                                    <label style={{ color: "#939393" }}>{modalCaseInfo?.data?.description ? parse(modalCaseInfo.data.description) : "-"}</label>
                                </div>
                            </Col>

                            <Col xs={24} md={12} xl={12}>
                                <div style={{ display: "grid" }}>
                                    <label>การดำเนิน Case</label>
                                    <label style={{ color: "#939393" }}>{modalCaseInfo?.data?.caseProcess ?? "-"}</label>
                                </div>
                            </Col>

                            <Col xs={24} md={12} xl={12}>
                                <div style={{ display: "grid" }}>
                                    <label>ประเภท Case</label>
                                    <label style={{ color: "#939393" }}>{modalCaseInfo?.data?.caseType ?? "-"}</label>
                                </div>
                            </Col>

                            {/* <Col xs={24} md={12} xl={12}>
                                <div style={{ display: "grid" }}>
                                    <label>ประเภท Case ย่อย</label>
                                    <label style={{ color: "#939393" }}>{modalCaseInfo?.data?.case_subtype?.name ?? "-"}</label>
                                </div>
                            </Col> */}

                            <Col xs={24} md={12} xl={12}>
                                <div style={{ display: "grid" }}>
                                    <label>วันที่จะเเก้ไขเสร็จ</label>
                                    <label style={{ color: "#939393" }}>{modalCaseInfo?.data?.completionDate ? modalCaseInfo?.data?.completionDate : "-"}</label>
                                </div>
                            </Col>

                            <Col xs={24} md={12} xl={12}>
                                <div style={{ display: "grid" }}>
                                    <label>โปรเจกต์</label>
                                    <label style={{ color: "#939393" }}>{modalCaseInfo?.data?.project ?? "-"}</label>
                                </div>
                            </Col>

                            <Col xs={24} md={12} xl={12}>
                                <div style={{ display: "grid" }}>
                                    <label>ค่าใช้จ่ายในการดำเนินงาน</label>
                                    <label style={{ color: "#939393" }}>{modalCaseInfo?.data?.operationCost ? (Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(modalCaseInfo?.data?.operationCost)) + " บาท") : "-"}</label>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div style={{ width: "100%" }}>
                        <Row gutter={[6, 6]}>
                            <Col span={24}>
                                <div style={{ padding: 12, backgroundColor: "#EEEEEE", borderRadius: 6 }}>
                                    <div style={{ display: "flex", alignItems: "center", paddingBottom: 6 }}>
                                        <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                        <label style={{ paddingLeft: 8 }}>รูปภาพเเนบ ใช้ประกอบการอธิบาย Case</label>
                                    </div>
                                    <Row gutter={[6, 6]}>
                                        {modalCaseInfo?.data?.caseImage?.map((val, index) => {
                                            return (
                                                <Col key={index} xs={24} md={12} xl={6}>
                                                    <div style={{ display: "flex", justifyContent: "center", backgroundColor: "#000000" }}>
                                                        <Image
                                                            preview
                                                            src={`${val.url}`}
                                                            style={{ objectFit: "cover", width: "100%", height: 100 }}
                                                        />
                                                    </div>
                                                </Col>
                                            )
                                        })}

                                        {modalCaseInfo?.data?.caseImage?.length === 0 ?
                                            <Col span={24}>
                                                <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                    <label>ไม่มีรูปภาพ</label>
                                                </div>
                                            </Col>
                                            : []
                                        }
                                    </Row>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div style={{ padding: 12, backgroundColor: "#EEEEEE", borderRadius: 6 }}>
                                    <div style={{ display: "flex", alignItems: "center", paddingBottom: 6 }}>
                                        <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                        <label style={{ paddingLeft: 8 }}>วิดีโอเเนบ ใช้ประกอบการอธิบาย Case</label>
                                    </div>
                                    <Row gutter={[12, 24]}>
                                        {modalCaseInfo?.data?.caseVideo?.map((val) => {
                                            return (
                                                <Col xs={24} md={24} xl={24}>
                                                    <div style={{ display: "flex" }}>
                                                        <Video url={val.url} title={""} height={""} />
                                                    </div>
                                                </Col>
                                            )
                                        })}

                                        {modalCaseInfo?.data?.caseVideo?.length === 0 ?
                                            <Col span={24}>
                                                <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                    <label>ไม่มีวิดีโอ</label>
                                                </div>
                                            </Col>
                                            : []
                                        }
                                    </Row>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div style={{ padding: 12, backgroundColor: "#EEEEEE", borderRadius: 6 }}>
                                    <div style={{ display: "flex", alignItems: "center", paddingBottom: 6 }}>
                                        <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                        <label style={{ paddingLeft: 8 }}>ไฟล์เเนบ (.pdf, .docx, .xlsx, .csv) ใช้ประกอบการอธิบาย Case</label>
                                    </div>
                                    <Row gutter={[12, 24]}>
                                        {modalCaseInfo?.data?.caseFile?.map((val) => {
                                            return (
                                                <Col xs={24} md={24} xl={24}>
                                                    <div style={{ display: "flex", border: "2px solid #a8a8a8", borderRadius: 6, padding: 12 }}>
                                                        <div style={{ display: "flex", cursor: "pointer" }}>
                                                            <Icon icon="fa:file" style={{ width: 70, height: 70, color: "#429321" }} />
                                                            <label style={{ paddingLeft: 6, cursor: "pointer" }}>
                                                                {val.name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })}

                                        {modalCaseInfo?.data?.caseFile?.length === 0 ?
                                            <Col span={24}>
                                                <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                    <label>ไม่มีไฟล์</label>
                                                </div>
                                            </Col>
                                            : []
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal>
        </div >
    )
}
