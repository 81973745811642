/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useState } from "react"
import "../footer/css/index.css"
import { BackTop, Col, Image, Row, Tooltip, Input, Button, Modal } from "antd"
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../mamager/ScreenManager"

function Footer() {

    const navigate = useNavigate()
    const { width } = useDimensions()

    const [isModalLineVisible, setIsModalLineVisible] = useState(false)

    const handleModalLine = () => {
        setIsModalLineVisible(false)
    }

    return (
        <footer>
            <div
                style={{
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#1E2736",
                }}
            >
                <nav
                    style={{

                        color: "white",
                        // paddingLeft: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 12 : 12),
                        // paddingRight: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 12 : 12),
                        paddingTop: 50,
                        paddingBottom: 50,
                        maxWidth: 1000,
                    }}
                >
                    <Row gutter={[24, 24]}>
                        <Col xs={24} md={12} xl={16}>
                            <div style={{ display: "grid", paddingLeft: (width > 1200 ? 0 : 24) }}>
                                <div style={{ paddingBottom: (width > 1200 ? 0 : 24) }}>
                                    <Image
                                        fallback={"./assets/images/logo/logo_spm_white.png"}
                                        src="error"
                                        preview={false}
                                        style={{ width: 100, height: 100 }}
                                    />
                                </div>

                                <div>
                                    <div style={{ paddingBottom: 12, paddingTop: 12 }}>
                                        <label style={{ fontSize: 22, fontWeight: 600 }}>Soft Po Market</label>
                                    </div>

                                    <div style={{ paddingBottom: 12 }}>
                                        <label style={{}}>บริการอย่างเป็นมิตร ใส่ใจในทุกรายละเอียด  เรามีความเชี่ยวชาญเฉพาะด้านที่หลากหลาย ช่วยยกระดับธุรกิจของคุณให้ถึงเป้าหมายที่คาดหวัง</label>
                                    </div>

                                    <div style={{ paddingBottom: 12 }}>
                                        <label style={{ color: "#8C8C8C" }}>Phone number</label>
                                    </div>

                                    <div style={{ paddingBottom: 12, display: "flex", alignItems: "center" }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Image
                                                fallback={"./assets/images/contact/phone.png"}
                                                src="error"
                                                preview={false}
                                                style={{ width: 24, height: 24 }}
                                            />
                                            <a href="tel:+66642451994" style={{ paddingLeft: 8, color: "white" }}>064-245-1994 (คุณเอก)</a>
                                        </div>

                                        <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                                            <Image fallback={"./assets/images/contact/distance.png"} src="error" preview={false} />
                                        </div>

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Image
                                                fallback={"./assets/images/contact/phone.png"}
                                                src="error"
                                                preview={false}
                                                style={{ width: 24, height: 24 }}
                                            />
                                            <a href="tel:+66642451994" style={{ paddingLeft: 8, color: "white" }}>02-114-3464</a>
                                        </div>
                                    </div>

                                    <div style={{ paddingBottom: 12 }}>
                                        <label style={{ color: "#8C8C8C" }}>Social network</label>
                                    </div>

                                    <div style={{ paddingBottom: 0, display: "flex", alignItems: "center" }}>
                                        <div style={{ paddingRight: 12 }}>
                                            <Image
                                                fallback={"./assets/images/contact/facebook.png"}
                                                src="error"
                                                preview={false}
                                                style={{ width: 40, height: 40, cursor: "pointer" }}
                                            />
                                        </div>

                                        <div style={{}}>
                                            <Image
                                                fallback={"./assets/images/contact/line.png"}
                                                src="error"
                                                preview={false}
                                                style={{ width: 40, height: 40, cursor: "pointer" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={12} xl={8}>
                            <div style={{ paddingRight: (width > 1200 ? 0 : 24), paddingLeft: (width > 767 ? 0 : 24) }} >
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.9197804464407!2d100.55570277128976!3d13.783709257021506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29c269271defb%3A0x7537d3d0e6b9a362!2s110%20Soi%20Vibhavadi%20Rangsit%204%2C%20Khwaeng%20Din%20Daeng%2C%20Khet%20Din%20Daeng%2C%20Krung%20Thep%20Maha%20Nakhon%2010400%20%E3%82%BF%E3%82%A4!5e0!3m2!1sja!2sjp!4v1728278730433!5m2!1sja!2sjp"
                                    style={{ border: 0, width: "100%", height: 368 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </Col>
                    </Row>
                </nav>
            </div>

            <nav style={{ backgroundColor: "#262626", color: "white", paddingLeft: '5%', paddingRight: '5%', paddingTop: 15, paddingBottom: 15, textAlign: 'center' }}>
                <label>Copyright © 2024 Soft Po Market All Right Reserved.</label>
            </nav>

            <Modal
                title="ไลน์"
                open={isModalLineVisible}
                onCancel={handleModalLine}
                // width={1000}
                footer={[]}
            >
                <img
                    style={{ objectFit: "cover", width: "100%", height: 500, borderRadius: 8 }}
                    src={`./assets/images/contact/line-qrcode.jpg`}
                />
            </Modal>
        </footer>
    )
}

export default Footer
