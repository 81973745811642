import React from 'react'
import Header from '../../../common/components/header'
import Footer from '../../../common/components/footer'
import { Col, Row } from 'antd'
import CaseFrom from './components/CaseFrom'
import { useDimensions } from '../../../common/mamager/ScreenManager'

export default function OpenCase() {

    const auth = JSON.parse(sessionStorage.getItem('auth'))
    // console.log("auth : ", auth)

    const { width } = useDimensions()

    return (
        <>
            <Header />

            <article>
                <div
                    style={{
                        // backgroundColor: "",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 94,
                            paddingBottom: 24,

                            maxWidth: 1000,
                        }}
                    >
                        <Row>
                            <Col span={24}>
                                <CaseFrom />
                            </Col>
                        </Row>
                    </div>
                </div>
            </article>

            <Footer />
        </>
    )
}
